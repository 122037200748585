import { saveAs } from 'file-saver';

import './App.scss';
import tabletIntroBg from './assets/callout-image-tablet.jpg';
import mobileIntroBg from './assets/callout-image-mobile.jpg';
import artBg from './assets/callout-image-desktop.jpg';

import introBg from './assets/hero.jpg';

import blackLogo from './assets/blackLogo.svg';
import whiteLogo from './assets/whiteLogo.svg';

import downloadImg from './assets/download.svg';

import t3Logo from './assets/t3.svg';

import hinesLogo from './assets/hinesLogo.svg';
import urbanStreetLogo from './assets/urbanStreetLogo.svg';

import brochurePDF from './assets/brochure.pdf';

function App() {
  const downloadPDF = () => {
    window.dataLayer.push({
      event: 'downloadTracking',
      action: 'Download',
      item: `Leasing Brochure`,
    });
    saveAs(brochurePDF, 'T3 FAT Village Digital Leasing Brochure.pdf');
  };

  return (
    <div className="App">
      <section className="intro">
        <img className="desktopLogo" src={blackLogo} alt="logo" />
        <div className="tabletIntro">
          <div className="tabletLogoWrapper">
            <img className="tabletLogo" src={whiteLogo} alt="logo" />
          </div>
          <div className="tabletBg">
            <img
              className="tabletIntroBg"
              src={tabletIntroBg}
              alt="T3 Fat Village transforms the way you work."
            />
            <img
              className="mobileIntroBg"
              src={mobileIntroBg}
              alt="T3 Fat Village transforms the way you work."
            />
          </div>
        </div>
      </section>
      <section className="content">
        <div className="artWrapper">
          <img
            className="artBg"
            src={artBg}
            alt="T3 Fat Village transforms the way you work."
          />
          <img className="tabletPicture" src={introBg} alt="t3 fat village" />
          <div className="contentRight">
            <div className="t3">
              <header>
                <img className="t3Logo" src={t3Logo} alt="t3" />
              </header>
              <div className="t3Content">
                <p>
                  In the heart of FAT Village, T3 is where the art of office is
                  reimagined — redefining our work lives. Mass timber as the
                  building's structure, and exposed wood as its signature
                  feature. Vast warehouse-inspired spaces designed for
                  collaboration and inspiration. Amenities created for daily
                  health. The balance of human wellness, wrapped in the goodness
                  of wood.
                  <br />
                  <br />
                  Located in a vibrant arts community, with the best of Fort
                  Lauderdale's food, beverage, and lifestyle outside the door.
                  Connected to transit, with Brightline steps away. Wired for
                  the future, with leading tech infrastructure.
                  <br />
                  <br />
                  It's how the work world moves forward. And it's where
                  Florida's top talent wants to be.
                </p>
              </div>
            </div>
            <div className="download" aria-hidden onClick={downloadPDF}>
              <div className="downloadOverlay" />
              <div className="downloadText">
                <h3>Download Leasing Brochure</h3>
              </div>
              <hr />
              <div className="downloadButton">
                <img src={downloadImg} alt="download" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="contactInfoWrapper">
        <div className="leasingInquiries">
          <h3>Leasing Inquiries</h3>
        </div>

        <div className="contactInfo">
          <div className="contactRow">
            <div className="contact">
              <div className="contactInner">
                <h4>Christina Jolley</h4>
                <a href="tel: +15612517801">561.251.7801</a>
                <a href="mailto: christina.jolley@blancacre.com">
                  christina.jolley@blancacre.com
                </a>
              </div>
            </div>

            <div className="contact">
              <div className="contactInner">
                <h4>Sky Jones</h4>
                <a href="tel: +3055778850">305.577.8850</a>
                <a href="mailto: sky.jones@blancacre.com">
                  sky.jones@blancacre.com
                </a>
              </div>
            </div>
            <div className="contact">
              <div className="contactInner">
                <h4>Alan Kennedy</h4>
                <a href="tel: +4044062164">404.406.2164</a>
                <a href="mailto: alan.kennedy@hines.com">
                  alan.kennedy@hines.com
                </a>
              </div>
            </div>
          </div>
          <div className="contactRow">
            <div className="contact">
              <div className="contactInner">
                <h4>Danet Linares</h4>
                <a href="tel: +3055778852">305.577.8852</a>
                <a href="mailto: danet.linares@blancacre.com">
                  danet.linares@blancacre.com
                </a>
              </div>
            </div>
            <div className="contact">
              <div className="contactInner">
                <h4>Tere Blanca</h4>
                <a href="tel: +3055778851">305.577.8851</a>
                <a href="mailto: tere.blanca@blancacre.com">
                  tere.blanca@blancacre.com
                </a>
              </div>
            </div>
            <div className="contact">
              <div className="contactInner">
                <h4>Juan Jacobus</h4>
                <a href="tel: +3057336005">305.733.6005</a>
                <a href="mailto: juan.jacobus@hines.com">
                  juan.jacobus@hines.com
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer>
        <small>T3 FAT Village &copy; 2024 - All Rights Reserved</small>

        <div className="footerLogos">
          <a href="https://www.hines.com/" target="_blank" rel="noreferrer">
            <img className="hinesLogo" src={hinesLogo} alt="Hines" />
          </a>
          <a
            href="https://urbanstreetdevelopment.com/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="urbanLogo"
              src={urbanStreetLogo}
              alt="Urban Street Development"
            />
          </a>
        </div>
      </footer>
    </div>
  );
}

export default App;
